import React from 'react';

const Location = () => {
  return (
    <div className="container">
      <h1>Our Location</h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0191169825765!2d76.04393121604622!3d10.981489892205008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7b996791c5c9f%3A0x45a3af7c8d8b4792!2sPerinthalmanna-Kozhikkode%20Rd%2C%20Malappuram%20dt!5e0!3m2!1sen!2sin!4v1628493316958!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="LogOnSiS Location"
      ></iframe>
    </div>
  );
};

export default Location;
