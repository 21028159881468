import React from 'react';

const Contact = () => {
  return (
    <div className="container">
      <h1>Contact Us</h1>
      <p>Abdun Nazeer Kizhakke Thalakka</p>
      <p>Phone: +918157822165</p>
      <p>Email: a.nazeer@logonsis.com</p>
    </div>
  );
};

export default Contact;
