import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <Navbar bg="dark" variant="dark" className="flex-column" fixed="left" color='darkblue'>
      <Navbar.Brand href="/">LogOnSiS</Navbar.Brand>
      <Nav className="flex-column">
        <Nav.Link as={NavLink} to="/">
          Home
        </Nav.Link>
        <Nav.Link as={NavLink} to="/services">
          Services
        </Nav.Link>
        <Nav.Link as={NavLink} to="/contact">
          Contact
        </Nav.Link>
        <Nav.Link as={NavLink} to="/location">
          Location
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default Navigation;
