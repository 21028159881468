import React from 'react';

const Services = () => {
  return (
    <div className="container">
      <h1>Our Services</h1>
      <ul>
        <li>Computer Software Development</li>
        <li>Web Application Development</li>
        <li>Computer System Support</li>
        <li>Research and Development Projects</li>
        <li>Guidance for Project Developments for Technical Students</li>
      </ul>
    </div>
  );
};

export default Services;
